<template>
  <v-card>
    <v-card-title class="justify-space-between mb-0 py-7">
      <span class="text-h5"><strong>Tissue Offered</strong></span>
    </v-card-title>
    <!-- <v-card-title class="justify-center">
      <span class="text-h5"><strong> Tissue Offered</strong></span>
    </v-card-title> -->
    <v-row class="ml-2 mr-2 mb-3">
      <v-col cols="12" md="3">
        <v-text-field v-model="filter.tissueid" label="Tissue ID" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="pl-0">
        <v-text-field label="Hospital Name" v-model="filter.hospital_name" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="pl-0">
        <v-text-field v-model="filter.surgeon_name" label="Surgeon Name" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12"  md="3" class="pl-0 text-right">
        <v-btn color="primary" class="mr-2" @click="TissueOfferedSearch()"> Search</v-btn>
        <v-btn color="primary"  @click="TissueView()">Reset</v-btn>
      </v-col>
    </v-row>

    <v-data-table :headers="headers" :items="TissueList" :items-per-page="10" fixed-header height="400"
      class="table-rounded" item-key="tissue_id" :single-expand="singleExpand" :expanded.sync="expanded" show-expand>

      <template #[`item.recovery_date_time`]="{ item }">
          <pre>{{ moment.utc(item.recovery_date_time).format('DD-MM-YYYY HH:mm') }}</pre>
      </template>

      <template #[`item.tissue_offered_date_time`]="{ item }">
          <pre>{{ item.tissue_offered_date_time == "" || item.tissue_offered_date_time == "Invalid date" ? "" : moment.utc(item.tissue_offered_date_time).format('DD-MM-YYYY HH:mm') }}</pre>
      </template>

      <template #[`item.action`]="{ item }">
        <v-btn color="primary" v-if="item.pdf_path != '' || item.tissue_outcome != 'Response Awaiting'" :href="`https://api.stage.ebsr.in/${item.pdf_path}`" target="_blank"> 
          Print 
        </v-btn>
      </template>

      <template #[`item.operate`]="{ item }">
        <v-btn color="primary" class="mr-3" @click="editform(item)"> Offer </v-btn>
        <EditOD :visible="editAdd" :hospitalCat="item" :item="editItem" :newItem="newEditItem" :allItem="allItem" @close="editAdd = false" />
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <p class="justify-center d-flex"><strong> Progress Bar </strong></p>
          <v-stepper v-model="item.progress_count" alt-labels class="ml-n9">
            <v-stepper-header>
              <v-stepper-step :complete="item.progress_count > 1" step="1">
                <div class="text-center">Response Awaiting</div>
              </v-stepper-step>
              <v-divider></v-divider>
              <!-- <v-stepper-step :complete="item.progress_count > 2" step="2"><div class="text-center">Response Awaiting<br/>For Transplantation</div></v-stepper-step> -->
              <!-- <v-divider></v-divider> -->
              <v-stepper-step :complete="item.progress_count > 2 && item.tissue_outcome != 'Research'" step="2">
                <div class="text-center">Accepted</div>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="item.progress_count > 3 && item.tissue_outcome != 'Research'" step="3">
                <div class="text-center">Distributed</div>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="item.progress_count > 4 && item.tissue_outcome != 'Research'" step="4">
                <div class="text-center">Withdrawal</div>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="item.progress_count > 5 && item.tissue_outcome != 'Research'" step="5">
                <div class="text-center">Returned</div>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="item.progress_count > 6" step="6">
                <div class="text-center">Research</div>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="7">
                <div class="text-center">Discard</div>
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </td>
      </template>

    </v-data-table>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import TissueService from '@/service/Tissue.service'
import moment from 'moment'
import { api } from '@/config/config'
const localStoragedata = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
import EditOD from './Edit.vue'

const token = localStoragedata.token
export default {
  components: { EditOD },
  data() {
    return {
      moment,
      TissueList: [],
      expanded: [],
      editItem: '',
      newEditItem: '',
      allItem: '',
      editAdd: false,
      hospitalCat: '',
      singleExpand: true,
      Donor_name: '',
      Tissue_no: '',
      listLoading: false,
      totallist: 0,
      snackbar: false,
      snackbarText: '',
      timeout: 2000,

      filter: {
        tissueid: '',
        hospital_name: '',
        surgeon_name: '',
      },

      headers: [
        { text: 'Tissue Recovery Date & Time', value: 'recovery_date_time', width: '150px' },
        { text: 'Tissue Offered Date & Time', value: 'tissue_offered_date_time', width: '150px' },
        { text: 'Days to Expire', value: 'days_to_expire', width: '150px' },
        { text: 'Tissue ID', value: 'tissue_id', width: '150px' },
        { text: 'D to P', value: 'death_to_preservation_time', width: '150px' },
        { text: 'Age (Age of Donor) ', value: 'age', width: '150px' },
        { text: 'Cell count', value: 'cell_count', width: '150px' },
        { text: 'Approved Usages ', value: 'approved_usages', width: '150px' },
        { text: 'Tissue Category', value: 'tissue_category', width: '150px' },
        { text: 'Hospital Name', value: 'hospital_name', width: '150px' },
        { text: 'Surgeon Name', value: 'surgeon_name', width: '150px' },
        { text: 'Hospital Category', value: 'hospital_category', width: '150px' },
        { text: 'Tissue Outcome', value: 'tissue_outcome', width: '150px' },
        { text: 'Operate', value: 'operate', width: '150px' },
        { text: 'Action', value: 'action', width: '150px' },
      ],
    }
  },
  watch: {
    options: {
      handler() {
        this.TissueView()
      },
      deep: true,
    },
  },

  async mounted() {
    this.TissueView()

  },

  methods: {
    
    // newsame(item){
    //   if(this.item.progress_count == "5"){
    //     var newitem = "2"
    //   }else {
    //     var newitem = this.item.progress_count
    //   }
    // },


    editform(e) {
      if(e.bill_status == 1){
        this.snackbarText = `Please cancel first bill of lading of this "${e.tissue_id}" tissue number`
        this.snackbar = true
      }else{
        this.TissueView()
        this.editAdd = true
        this.editItem = e
        this.hospitalCat = e
        this.getSingleTissueData(e)
      }
    },

    async getSingleTissueData(allData) {
      var data = {
        donor_id: allData.donor_id,
        tissue_id: allData.tissue_id,
      }
      const service = new TissueService()
      let response = await service.SingleTissueData(data)
      let responseData = response[0]
      this.newEditItem = responseData
    },

    async TissueView() {
      this.listLoading = true
      const service = new TissueService()
      let response = await service.TissueOffered()
      if (response) {
        this.TissueList = response
        this.allItem = response;
        this.totallist = this.TissueList.length;
        (this.filter.tissueid = ''),
          (this.filter.hospital_name = ''),
          (this.filter.surgeon_name = '')

      } else {
        this.TissueList = []
        this.totallist = 0
      }
    },


    async TissueOfferedSearch() {
      this.listLoading = true
      
      const data = {
        tissue_id: this.filter.tissueid,
        hospital_name: this.filter.hospital_name,
        surgeon_name: this.filter.surgeon_name,
      }

      if (this.filter.tissueid == '' && this.filter.hospital_name == '' && this.filter.surgeon_name == '') {
        this.snackbarText = 'Kindly Enter The Keyword',
        this.snackbar = true
        return;
      }

      try {
        const response = await api.post(`tissueOffer/tissue_offer_searching`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (response.data.length > 0) {
          this.TissueList = response.data
          this.totallist = this.TissueList.length
        } else {
          this.TissueList = []
          this.totallist = 0
        }
        this.listLoading = false
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
